//Libraries
import React from 'react';
import Img from 'gatsby-image'
import cs from 'classnames'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../../context/fontSizeContext'

//Types
import type { FunctionComponent } from 'react'
import type { CommunityPartnerTemplateTypes } from '../z_components-types';

//Styles
import styles from './css/communityPartnerTemplate.module.scss'

//Components
import IconWithText from '../free/iconWithText'
import Header from '../header/header'
import Layout from '../layout/layout'
import Footer from '../footer/footer'

const CommunityPartnerTemplate: FunctionComponent<CommunityPartnerTemplateTypes> = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter: partner, html } = markdownRemark
    const { name, img, address, city, fax, phone, email, volunteerInfo, isImgContain, website } = partner

    const openPrintDialog: () => void = () => {
        window.print()
    }

    return (
        <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
            <meta charSet="utf-8" />
            <title>OOA - {name}</title>
            <link
            rel="canonical"
            href={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <meta
            name="description"
            content={`Contact information about ${name}`}
            />
        </Helmet>

        <Header />

        <Layout>
            <div className={styles.communityPartnerContainer}>
                <div className={styles.partnerIdentity}>
                    <i
                        onClick={openPrintDialog}
                        className={cs('ri-printer-fill', styles.printButton)}
                    ></i>
                    <Img
                        fluid={img.childImageSharp.fluid}
                        className={cs(
                            styles.partnerImg,
                            isImgContain === 'Yes' ? styles.contain : ''
                        )}
                        alt="Picture of Senior Center"
                    />

                    <div className={styles.infoContainer}>
                        <h2 className={styles.partnerTitle}>{name}</h2>
                        <IconWithText
                            icon="ri-home-4-line"
                            text={[address, city]}
                        />

                        <IconWithText icon="ri-mail-line" emails={[email]} />

                        <IconWithText
                            icon="ri-phone-line"
                            tels={[
                            fax && fax.toLowerCase() === 'same'
                                ? `Phone and Fax: ${phone}`
                                : `Phone: ${phone}`,
                            fax &&
                                fax.toLowerCase() !== 'same' ?
                                `Fax: ${fax}` : '',
                            ]}
                        />

                        <IconWithText
                            icon="ri-global-line"
                            links={[
                            {
                                url: website,
                                urlText: 'Website',
                            }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.partnerDescription}>
                <span dangerouslySetInnerHTML={{ __html: html }}></span>
            </div>

            <div className={styles.notes} style={{ marginTop: '16px' }}>
                {volunteerInfo?.contact && (
                    <>
                        <h2 id="volunteer" className={styles.partnerTitle}>Volunteer Opportunity</h2>
                        { volunteerInfo?.contact && (
                            <>
                                <p>If you'd like to volunteer at this Community Partner, please reach out to the conact below</p>
                                <IconWithText
                                    icon="ri-user-line"
                                    text={[volunteerInfo.contact]}
                                />
                            </>
                        )}
                        { volunteerInfo.email && (
                            <IconWithText
                                icon="ri-mail-line"
                                emails={[volunteerInfo.email]}
                            />
                        )}
                        { volunteerInfo.phone && (
                            <IconWithText
                                icon="ri-phone-line"
                                tels={[volunteerInfo.phone]}
                            />
                        )}
                        <p className={styles.volunteerBody} dangerouslySetInnerHTML={{ __html: volunteerInfo.description }}></p>
                    </>
                )}
            </div>
        </Layout>
        <Footer />
    </FontSizeProvider>
    )
}

export default CommunityPartnerTemplate;

export const CommunityPartnerQuery = graphql`
    query( $slug: String! ) {
        markdownRemark( fields: { slug: { eq: $slug } } ) {
            html
            frontmatter {
                name
                isImgContain
                img {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                address
                city
                email
                phone
                fax
                website
                volunteerInfo {
                    contact
                    phone
                    email
                    description
                }
            }
        }
    }
`